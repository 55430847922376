import { graphql } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SectionIntro from "../components/ui/SectionIntro/SectionIntro";

interface AboutPageProps {
  data: {
    prismicAboutPage: AboutPageData;
  };
  location: Location;
}

const AboutPage: React.FC<AboutPageProps> = ({ data, location }) => {
  const aboutPageData = data.prismicAboutPage.data;

  return (
    <Layout>
      <SEO
        title={aboutPageData.seo[0].seo_title}
        description={aboutPageData.seo[0].seo_description}
        slug={location.pathname}
      />

      <section className="bg-gray-800">
        <div className="container">
          <h1 className="text-4xl text-white leading-none mb-0">About us</h1>
        </div>
      </section>

      {aboutPageData.body.map((slice, index) => {
        if (slice.__typename === "PrismicAboutPageBodyFeatureSection") {
          return (
            <section
              key={`${slice.__typename}-${index}`}
              id={index === 0 ? "about" : "benefits"}
              className="odd:bg-gray-50"
            >
              <div className="container">
                <div
                  className={`flex flex-wrap ${
                    slice.primary.image_position === "right"
                      ? "lg:flex-row-reverse"
                      : ""
                  } items-center lg:justify-between max-w-2xl mx-auto lg:max-w-none`}
                >
                  <div className="lg:w-6/12 w-full max-w-screen-sm mb-5 lg:mb-0">
                    <GatsbyImage
                      image={
                        slice.primary.image.localFile.childImageSharp
                          .gatsbyImageData
                      }
                      alt={slice.primary.image.alt || ""}
                    />
                  </div>

                  <div className="w-full lg:w-5/12 max-w-screen-sm">
                    <SectionIntro
                      subHeading={slice.primary.sub_heading.text}
                      heading={slice.primary.heading.text}
                    />
                    {slice.primary.description.raw.map(
                      ({ text }, textIndex) => (
                        <p
                          key={`${slice.__typename}-${index}-text-${textIndex}`}
                          className="mb-3 last:mb-0"
                        >
                          {text}
                        </p>
                      )
                    )}
                  </div>
                </div>
              </div>
            </section>
          );
        } else return null;
      })}
    </Layout>
  );
};

export default AboutPage;

export const pageQuery = graphql`
  query {
    prismicAboutPage {
      id
      data {
        title {
          text
        }
        seo {
          seo_title
          seo_description
        }
        body {
          __typename
          ... on PrismicAboutPageBodyFeatureSection {
            id
            primary {
              sub_heading {
                text
              }
              heading {
                text
              }
              description {
                raw
              }
              image {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                  }
                }
              }
              image_position
            }
          }
        }
      }
    }
  }
`;

interface AboutPageData {
  data: {
    title: {
      text: string;
    };
    seo: {
      seo_title: string;
      seo_description: string;
    }[];
    body: [AboutFeatureSection];
  };
}

interface AboutFeatureSection {
  __typename: "PrismicAboutPageBodyFeatureSection";
  primary: {
    image: {
      localFile: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
        };
      };
      alt: string;
    };
    image_position: "left" | "right";
    heading: {
      text: string;
    };
    sub_heading: {
      text: string;
    };
    description: {
      raw: {
        type: string;
        text: string;
      }[];
    };
  };
}
